import { Button } from "../../components/Common";

interface SideBarProps {
  user: {
    avatarURL: string;
    fullName: string;
    phoneNumber: string;
  };
  currentSetting: {
    id: string;
    title: string;
    subSettings: Array<{ id: string; title: string }>;
  };
  setCurrentSetting: (value: {
    id: string;
    title: string;
    subSettings: Array<{ id: string; title: string }>;
  }) => void;
}

const SideBar: React.FC<SideBarProps> = ({
  user,
  currentSetting,
  setCurrentSetting,
}) => {
  const settings: Array<{
    id: string;
    title: string;
    subSettings: Array<{ id: string; title: string }>;
  }> = [
    {
      id: "0",
      title: "Lịch sử giao dịch",
      subSettings: [
        {
          id: "2",
          title: "Thông báo",
        },
        {
          id: "3",
          title: "Học tập",
        },
      ],
    },
    {
      id: "1",
      title: "Tiến trình học tập của bé",
      subSettings: [],
    },
  ];

  const renderAccountSettings = () => {
    return settings.map((setting, index) => {
      return (
        <Button
          key={index}
          full
          className="mb-4 py-3"
          active={currentSetting.id === setting.id ? "active" : "inactive"}
          onClick={() => {
            setCurrentSetting(setting);
          }}
        >
          {setting.title}
        </Button>
      );
    });
  };

  const renderSubSettings = () => {
    if (currentSetting.subSettings.length > 0) {
      return currentSetting.subSettings.map((setting, index) => {
        return (
          <Button
            key={index}
            full
            className="mb-4 py-3 animate-appear"
            active={currentSetting.id === setting.id ? "active" : "inactive"}
          >
            {setting.title}
          </Button>
        );
      });
    }
  };

  return (
    <div className="w-full py-10 h-fit bg-white rounded-3xl flex flex-col items-center">
      <div className="w-28 h-28 rounded-full bg-[#F5F5F5] bg-cover">
        <img alt="" src={user.avatarURL} />
      </div>
      <p className="my-2 text-2xl text-default font-semibold">
        {user.fullName}
      </p>
      <p className="text-base font-medium">{user.phoneNumber}</p>
      <div className="w-full px-6 mt-8">
        {renderAccountSettings()}
        {renderSubSettings()}
      </div>
    </div>
  );
};

export default SideBar;
