interface HomeCardProps {
  urlLink: string;
  title: string;
  content: string;
  reverseRow: boolean;
}

function HomeCard({
  urlLink,
  title,
  content,
  reverseRow = false,
}: HomeCardProps) {
  const isImage = (link: string) => {
    return link.match(/\.(jpeg|jpg|gif|png|svg)$/) !== null;
  };

  const isVideo = (link: string) => {
    return link.match(/\.(mp4|webm|ogg)$/) !== null;
  };

  return (
    <div
      className={`w-full px-20 py-16 pv:max-md:px-5 pv:max-md:py-10 flex ${
        reverseRow ? "flex-row" : "flex-row-reverse"
      } justify-between gap-10 items-end pv:max-md:flex-col-reverse pv:max-md:justify-center pv:max-md:items-center`}
    >
      <div
        className={`w-[60%] h-fit md:max-xl:h-[80vh] dvmax:max-dh:h-[800px] flex ${
          reverseRow ? "justify-start" : "justify-end"
        } object-contain pv:max-md:w-full items-end`}
      >
        {isImage(urlLink) && <img src={urlLink} className="w-full h-full rounded-[30px]" alt={title} />}
        {isVideo(urlLink) && (
          <video
            id="myvideo"
            className="rounded-[30px] w-full h-fit md:max-xl:h-[80vh]"
            controls
            controlsList="nodownload"
          >
            <source src={urlLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="flex flex-col  justify-end w-[40%] pv:max-md:w-full pv:max-md:text-center">
        <p className="uppercase leading-tight tracking-wide font-[800] text-[#142548] dvmax:max-dh:text-[105px] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-dh:text-[60px]">
          {title.split(" ").slice(0, 2).join(" ")}
          <br />
          {title.split(" ").slice(2).join(" ")}
        </p>

        <p className="font-[500] text-wrap text-[#142548] text-[20px] dvmax:max-dh:text-[30px] sm:max-md:text-xl md:max-lg:text-[14px]">
          {content}
        </p>
      </div>
    </div>
  );
}

export default HomeCard;
