import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header";
import backgroundImg from "../../assets/login/bg_login.png";
import frameLogin from "../../assets/login/login_frame.png";
import tagLogin from "../../assets/login/login_animal_tag.png";

function LoginPage() {
  return (
    <div className="">
      <div
        className="w-[100%] h-[800px] sm:h-[1000px]"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute z-50 w-full">
          <Header />
        </div>

        <div className="w-[100%] h-[700px] justify-center flex pt-36">
          <img
            className="items-center"
            src={tagLogin}
            alt=""
            style={{ position: "absolute", zIndex: 1 }}
          />
          <div className="w-full mx-4 pvmax:mx-8 md:mx-0 ph:w-[531px] h-[360px] sm:h-[453px] bg-[#65AFFF] rounded-[30px] mt-[200px] pv:mt-[200px] pvmax:mt-[250px] ph:mt-[280px] px-5 pvmax:px-8 sm:px-12 pt-6">
            <p className="font-bold text-[#FFFFFF] text-[36px] sm:text-[52px]">
              Đăng nhập
            </p>
            <p className=" text-[#FFFFFF] text-[20px] mt-2 sm:mt-8">
              Số điện thoại
            </p>
            <input
              className=" w-full h-12 bg-[#9DCBFC] rounded-full mt-2 sm:mt-5 px-4 text-[#FFFFFF] font-semibold"
              placeholder="Số điện thoại"
            ></input>
            <div className=" w-[80%] pvmax:w-[195px] flex h-12 bg-[#142548] items-center justify-center rounded-full mt-2 sm:mt-5">
              <p className=" text-[#FFFFFF] text-[20px] font-semibold">
                Gửi mã OTP
              </p>
            </div>
            <div className="hidden mt-5 sm:flex">
              <span className=" text-[#142548] text-[20px]">
                Thông tin điều khoản
              </span>
              &nbsp;
              <span className=" text-[#FFFFFF] text-[20px]">và</span>&nbsp;
              <span className=" text-[#142548] text-[20px]">
                Chính sách bảo mật
              </span>
            </div>
            <div className=" mt-5 flex sm:hidden">
              <span className=" text-[#142548] text-[20px]">
                Thông tin điều khoản
              </span>
              &nbsp;
              <span className=" text-[#FFFFFF] text-[20px]">và</span>&nbsp;
            </div>
            <span className=" flex sm:hidden text-[#142548] text-[20px]">
              Chính sách bảo mật
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LoginPage;
