import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceCard from "../../components/Common/service_package_component/service_card";

function LayoutServiceCard() {
  const [expandedCard, setExpandedCard] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCardClick = (cardType: string) => {
    setExpandedCard(expandedCard === cardType ? null : cardType);
  };

  const handleCardDoubleClick = () => {
    // navigate("/add-to-cart");
    navigate("/");
  };

  const cards = [
    {
      packageColor: "#65AFFF",
      imageUrl: "baby1",
      packageType: "1",
      price: 149000,
      pricePerMonth: 49000,
    },
    {
      packageColor: "#9FD356",
      imageUrl: "baby2",
      packageType: "3",
      price: 499000,
      pricePerMonth: 166000,
    },
    {
      packageColor: "#F7BF57",
      imageUrl: "baby3",
      packageType: "6",
      price: 899000,
      pricePerMonth: 149000,
    },
    {
      packageColor: "#F56177",
      imageUrl: "baby4",
      packageType: "12",
      price: 1199000,
      pricePerMonth: 99000,
    },
  ];

  return (
    <div className="flex justify-center items-center h-[45rem] pv:max-sm:h-fit pv:max-sm:pb-10 gap-8 lg:max-dh:gap-28 md:max-xl:h-fit md:max-xl:pb-6  flex-wrap">
      {cards.map((card) => (
        <ServiceCard
          key={card.packageType}
          packageColor={card.packageColor}
          imageUrl={card.imageUrl}
          packageType={card.packageType}
          price={card.price}
          pricePerMonth={card.pricePerMonth}
          isExpanded={expandedCard === card.packageType}
          onClick={() => handleCardClick(card.packageType)}
          onDoubleClick={handleCardDoubleClick}
        />
      ))}
    </div>
  );
}

export default LayoutServiceCard;
