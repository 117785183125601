import { createBrowserRouter } from "react-router-dom";
import HomePage from "../../pages/home";
import TempPage from "../../pages/temp";
import LoginPage from "../../pages/login/login";
import { AccountPage } from "../../pages/Account";
import ForSchoolPage from "../../pages/forschool";
import ServicePackage from "../../pages/servicePackage";
import AddToCart from "../../pages/addToCart";
import Payment from "../../pages/payment";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/temp",
    element: <TempPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/account",
    element: <AccountPage />,
  },
  {
    path: "/forschool",
    element: <ForSchoolPage />,
  },
  {
    path: "/service-package",
    element: <ServicePackage />,
  },
  {
    path: "/add-to-cart",
    element: <AddToCart />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
]);

export { appRouter };
