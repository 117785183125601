import React from "react";

interface AboutProps {
  titleAbout: string;
  contentAbout: string;
}

function About({ titleAbout, contentAbout }: AboutProps) {
  return (
    <div className="flex justify-center">
      <div className="absolute w-[95%] lg:w-[96%] dvmax:w-[98%] dv:w-[95%] dvmax:dh:w-[98%] z-50">
        <div className="bg-white absolute w-full rounded-[33.5px] text-[#142548] h-[320px] dvmax:max-dh:h-[320px] px-[40px] py-[30px] flex justify-between items-center z-50">
          <div className="flex flex-col w-[400px] dvmax:max-dh:w-[450px] justify-between h-full">
            <a href="#" className="text-[40px] md:text-[30px] dvmax:max-dh:text-[44px] font-bold">
              {titleAbout}
            </a>
            <a
              href="#"
              className="text-[16px] dvmax:max-dh:text-[16px] font-medium overflow-hidden"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 20, // Adjust the number of lines before truncation
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis", // Ensures the ellipsis is shown
              }}
            >
              {contentAbout}
            </a>
          </div>
          <div className="w-[1px] h-full bg-[#142548] mx-12" />
          <div className="flex justify-between h-full w-full">
            <ul className="flex flex-col justify-between font-bold dvmax:max-dh:text-[20px] text-[20px] md:text-[14px] lg:text-[16px]">
              <li>
                <a href="#">Giới thiệu</a>
              </li>
              <li>
                <a href="#">Tầm nhìn & Sứ mệnh</a>
              </li>
              <li>
                <a href="#">Đội ngũ phát triển sản phẩm</a>
              </li>
              <li>
                <a href="#">Nghiên cứu</a>
              </li>
              <li>
                <a href="#">Tin tức/ Sự kiện</a>
              </li>
              <li>
                <a href="#">Khách hàng nói gì về chúng tôi</a>
              </li>
            </ul>
            <div className="w-[350px] lg:w-[400px] h-full rounded-2xl flex justify-center items-center">
              {/* Additional content can go here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
