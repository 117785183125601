type LessonContent = {
    lessonThumbnailUrl?: string,
    lessonTitle?: string,
    gameThumbnailUrl?: string,
    videoUrl?: string,
    age?: string,
    contentTitle?: string,
    target?: string,
};

const vietnamese_lessons: LessonContent[] = [
    {
        lessonTitle: 'Giới thiệu bảng chữ cái',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/WK_TV01_Nh%E1%BA%ADn%20Bi%E1%BA%BFt%20B%E1%BA%A3ng%20Ch%E1%BB%AF%20C%C3%A1i/WK_TV01_Nh%E1%BA%ADn%20Bi%E1%BA%BFt%20B%E1%BA%A3ng%20Ch%E1%BB%AF%20C%C3%A1i.mp4',
        age: '5-6',
        contentTitle: 'Phát triển ngôn ngữ',
        target: 'Nhận dạng chữ cái',
    },
    {
        lessonTitle: 'Nhận biết chữ a',
        age: '5-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/WK_TV02%20-%20Nh%E1%BA%ADn%20bi%E1%BA%BFt%20t%E1%BB%ABng%20ch%E1%BB%AF%20c%C3%A1i/TV02_Nh%E1%BA%ADn%20bi%E1%BA%BFt%20t%E1%BB%ABng%20ch%E1%BB%AF%20c%C3%A1i%20(a).mp4',
        contentTitle: 'Phát triển ngôn ngữ',
        target: 'Nhận dạng chữ cái',
    },
    {
        lessonTitle: 'Nhận biết chữ ă',
        age: '5-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/WK_TV02%20-%20Nh%E1%BA%ADn%20bi%E1%BA%BFt%20t%E1%BB%ABng%20ch%E1%BB%AF%20c%C3%A1i/TV02_Nh%E1%BA%ADn%20bi%E1%BA%BFt%20t%E1%BB%ABng%20ch%E1%BB%AF%20c%C3%A1i%20(%C4%83).mp4',
        contentTitle: 'Phát triển ngôn ngữ',
        target: 'Nhận dạng chữ cái',
    },
    {
        lessonTitle: 'Nhận biết chữ â',
        age: '5-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/WK_TV02%20-%20Nh%E1%BA%ADn%20bi%E1%BA%BFt%20t%E1%BB%ABng%20ch%E1%BB%AF%20c%C3%A1i/TV02_Nh%E1%BA%ADn%20bi%E1%BA%BFt%20t%E1%BB%ABng%20ch%E1%BB%AF%20c%C3%A1i%20(%C3%A2).mp4',
        contentTitle: 'Phát triển ngôn ngữ',
        target: 'Nhận dạng chữ cái',

    },
    {
        lessonTitle: 'Nhận biết chữ b',
        age: '5-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/WK_TV02%20-%20Nh%E1%BA%ADn%20bi%E1%BA%BFt%20t%E1%BB%ABng%20ch%E1%BB%AF%20c%C3%A1i/TV02_Nh%E1%BA%ADn%20bi%E1%BA%BFt%20t%E1%BB%ABng%20ch%E1%BB%AF%20c%C3%A1i%20(b).mp4',
        contentTitle: 'Phát triển ngôn ngữ',
        target: 'Nhận dạng chữ cái',

    },
    {
        lessonTitle: 'Dấu',
        age: '5-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/WK_TV08%20-%20D%E1%BA%A5u/WK_TV08%20nh%E1%BA%ADn%20bi%E1%BA%BFt%20c%C3%A1c%20d%E1%BA%A5u%20trong%20ti%E1%BA%BFng%20vi%E1%BB%87t%20thay%20tho%E1%BA%A1i%20fix.mp4',
        contentTitle: 'Phát triển ngôn ngữ',
        target: 'Nhận dạng dấu',

    },
    {
        lessonTitle: 'Nhận biết phụ âm ghép (ch)',
        age: '5-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/WK_TV03-%20Nh%E1%BA%ADn%20bi%E1%BA%BFt%20ph%E1%BB%A5%20%C3%A2m%20gh%C3%A9p_V%C3%A2n/CH.mp4',
        contentTitle: 'Phát triển ngôn ngữ',
        target: 'Nhận dạng phụ âm ghép',
    },
    {
        lessonTitle: 'Vần ai-ay',
        age: '5-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/WK_TV09_V%E1%BA%A7n(1)/TV%2009-V%E1%BA%A7n%201%20(%20ai%20ay).mp4',
        contentTitle: 'Phát triển ngôn ngữ',
        target: 'Nhận dạng vần',
    },

];
const math_lessons: LessonContent[] = [
    {
        lessonTitle: 'Tập đếm số',
        age: '3-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/Math/WK_TL01_t%E1%BA%ADp%20%C4%91%E1%BA%BFm%20s%E1%BB%91/WK_TL01%20-%20t%E1%BA%ADp%20%C4%91%E1%BA%BFm%20s%E1%BB%91%20thay%20tho%E1%BA%A1i.mp4',
        contentTitle: 'Làm quen với một số khái niệm sơ đẳng về toán',
        target: 'Tập hợp, số lượng, số thứ tự và đếm',
    },
    {
        lessonTitle: 'Nhận biết số 1',
        age: '3-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/Math/WK_TL20%20S%E1%BB%91%201/WK_TL20%20S%E1%BB%91%201%20final%20thay%20tho%E1%BA%A1i.mp4',
        contentTitle: 'Làm quen với một số khái niệm sơ đẳng về toán',
        target: 'Tập hợp, số lượng, số thứ tự và đếm',
    },
    {
        lessonTitle: 'Nhận biết số 3',
        age: '3-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/Math/WK_TL22%20S%E1%BB%91%203/WK_TL22%20S%E1%BB%91%203%20final.mp4',
        contentTitle: 'Làm quen với một số khái niệm sơ đẳng về toán',
        target: 'Tập hợp, số lượng, số thứ tự và đếm',
    },
    {
        lessonTitle: 'Nhận biết số 5',
        age: '3-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/Math/WK_TL24_S%E1%BB%91%205/WK_TL24_S%E1%BB%91%20.mp4',
        contentTitle: 'Làm quen với một số khái niệm sơ đẳng về toán',
        target: 'Tập hợp, số lượng, số thứ tự và đếm',

    },
    {
        lessonTitle: 'So sánh cao thấp',
        age: '3-4',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/Math/WK_TL08_So%20s%C3%A1nh%20th%E1%BA%A5p%20h%C6%A1n%2C%20cao%20h%C6%A1n/WK_TL08_So%20s%C3%A1nh%20th%E1%BA%A5p%20h%C6%A1n%2C%20cao%20h%C6%A1n.mp4',
        contentTitle: 'Làm quen với một số khái niệm sơ đẳng về toán',
        target: 'So sánh, sắp xếp theo quy tắc',

    },
    {
        lessonTitle: 'Nhận biết hình',
        age: '3-4',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/Math/WK_TL02_Nh%E1%BA%ADn%20bi%E1%BA%BFt%20h%C3%ACnh/WK_TL02_Nh%E1%BA%ADn%20bi%E1%BA%BFt%20h%C3%ACnh%20BVN.mp4',
        contentTitle: 'Làm quen với một số khái niệm sơ đẳng về toán',
        target: 'Nhận biết, gọi tên các hình và nhận dạng các hình đó trong thực tế.',

    },
    {
        lessonTitle: 'Vị trí',
        age: '3-4',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/Math/WK_TL12_V%E1%BB%8B%20tr%C3%AD%20(30s)/WK_TL12_V%E1%BB%8B%20tr%C3%AD(30s).mp4',
        contentTitle: 'Làm quen với một số khái niệm sơ đẳng về toán',
        target: 'Định hướng trong không gian',
    },
    {
        lessonTitle: 'Giới thiệu số từ 1-10 và phép cộng',
        age: '5-6',
        videoUrl: 'https://storage.googleapis.com/why_kid_static_content/VIDEOS/Math/WK_TL06%20Gi%E1%BB%9Bi%20thi%E1%BB%87u%20s%E1%BB%91%201-10%20v%C3%A0%20ph%C3%A9p%20c%E1%BB%99ng/WK_TL06%20Gi%E1%BB%9Bi%20thi%E1%BB%87u%20s%E1%BB%91%201-10%20v%C3%A0%20ph%C3%A9p%20c%E1%BB%99ng%20final.mp4',
        contentTitle: 'Làm quen với một số khái niệm sơ đẳng về toán',
        target: 'Giới thiệu số từ 1-10 và phép cộng',
    },
];
const culture_lessons: LessonContent[] = [
    {
        lessonTitle: 'Ngày giỗ tổ Hùng Vương',
        age: "3-6",
        contentTitle: "Phát triển tình cảm",
        target: "Quan tâm đến văn hoá, lễ hội của quê hương, đất nước.",
    },
    {
        lessonTitle: 'Mừng ngày Phụ nữ',
        age: "3-6",
        contentTitle: "Phát triển tình cảm",
        target: "Quan tâm đến văn hoá, lễ hội của quê hương, đất nước.",
    },
    {
        lessonTitle: 'Tết Nguyên Đán',
        age: "3-6",
        contentTitle: "Phát triển tình cảm",
        target: "Quan tâm đến văn hoá, lễ hội của quê hương, đất nước.",
    },
    {
        lessonTitle: 'Vui trung thu',
        age: "3-6",
        contentTitle: "Phát triển tình cảm",
        target: "Quan tâm đến văn hoá, lễ hội của quê hương, đất nước.",
    },
    {
        lessonTitle: 'Rồng rắn lên mây',
        age: "3-6",
        contentTitle: "Phát triển tình cảm",
        target: "Quan tâm đến văn hoá, lễ hội của quê hương, đất nước.",
    },
    {
        lessonTitle: 'Bịt mắt bắt dê',
        age: "3-6",
        contentTitle: "Phát triển tình cảm",
        target: "Quan tâm đến văn hoá, lễ hội của quê hương, đất nước.",
    },

];
const skill_lessons: LessonContent[] = [
    {
        lessonTitle: 'Tập đánh răng',
        age: "3-6",
        contentTitle: "Giáo dục thể chất, dinh dưỡng, sức khoẻ",
        target: "Tập làm một số việc tự phục vụ trong sinh hoạt",
    },
    {
        lessonTitle: 'An toàn khi có cháy',
        age: "3-6",
        contentTitle: "Giáo dục thể chất, dinh dưỡng, sức khoẻ",
        target: "Giữ gìn sức khỏe và an toàn",
    },
    {
        lessonTitle: 'Không mở cửa cho người lạ',
        age: "3-6",
        contentTitle: "Giáo dục thể chất, dinh dưỡng, sức khoẻ",
        target: "Giữ gìn sức khỏe và an toàn",
    },
    {
        lessonTitle: 'An toàn trong nhà',
        age: "3-4",
        contentTitle: "Giáo dục thể chất, dinh dưỡng, sức khoẻ",
        target: "Giữ gìn sức khỏe và an toàn",
    },
    {
        lessonTitle: 'Những sắc thái cảm xúc',
        age: "3-4",
        contentTitle: "Phát triển tình cảm",
        target: "Nhận biết và thể hiện cảm xúc, tình cảm với con người, sự vật và hiện tượng xung quanh.",
    },
    {
        lessonTitle: 'Hãy nói lời chào hỏi',
        age: "3-4",
        contentTitle: "Phát triển kỹ năng xã hội",
        target: "Hành vi và quy tắc ứng xử xã hội",
    },
    {
        lessonTitle: 'Lớn lên bé muốn làm gì',
        age: "3-4",
        contentTitle: "Khám phá xã hội",
        target: "Nhận diện một số nghề trong xã hội",
    },
    {
        lessonTitle: 'Phương tiện giao thông',
        age: "3-4",
        contentTitle: "Khám phá khoa học",
        target: "Nhận diện và phân loại một số phương tiện giao thông",
    },

];

export {
    vietnamese_lessons,
    math_lessons,
    culture_lessons,
    skill_lessons,
}