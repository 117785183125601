import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <div className="w-[100%] bg-[#142548] h-auto flex text-white p-16 pb-36 pv:max-ph:p-8 ph:max-md:p-4 md:max-xl:p-6 pv:max-md:flex-col pv:max-md:gap-6 md:max-xl:gap-4 pv:max-md:items-center">
      <div className="w-[30%] pv:max-ph:w-[70%] ph:max-md:w-[40%] flex items-start pv:max-md:justify-center">
        <img
          className="w-[220px] dhmin:max-dh:w-[300px] pv:max-md:w-full"
          src="logo/logo_footer.png"
        />
      </div>
      <div className="flex w-[70%] h-60 justify-between  pv:max-xl:h-fit pv:max-md:w-full pv:max-xl:flex-wrap pv:max-xl:gap-4">
        <ul className="uppercase text-base dhmin:max-dh:text-[25px] dhmin:max-dh:font-bold flex flex-col h-auto justify-between w-[20%] pv:max-ph:w-[45%] ph:max-md:w-[30%] pv:max-md:justify-start pv:max-md:gap-3 md:max-xl:w-[35%]">
          <a className="pv:max-xl:font-medium pv:max-xl:normal-case	" href="#">
            Về chúng tôi
          </a>
          <a className="pv:max-xl:font-medium pv:max-xl:normal-case	" href="#">
            Về Whykids
          </a>
          <a className="pv:max-xl:font-medium pv:max-xl:normal-case	" href="#">
            Gói dịch vụ
          </a>
          <a
            className="pv:max-xl:font-medium pv:max-xl:normal-case	"
            href="/forschool"
          >
            Thư viện học liệu
          </a>
          <a className="pv:max-xl:font-medium pv:max-xl:normal-case	" href="#">
            Blog/ tin tức
          </a>
          <a className="pv:max-xl:font-medium pv:max-xl:normal-case	" href="#">
            Hỗ trợ
          </a>
        </ul>
        <div className="flex flex-col justify-between w-[20%] font-medium space-y-3 dhmin:max-dh:text-[25px]  pv:max-ph:w-[48%] ph:max-md:w-[30%] md:max-xl:w-[61%]">
          <a href="#" className="uppercase font-bold  ">
            Liên hệ
          </a>
          <a>Địa chỉ: 24 Tôn Đức Thắng, Đống Đa, Hà Nội</a>
          <a>
            Email: <br /> hotline@whycorp.vn
          </a>
          <div>Hotline: 0901762991</div>
        </div>
        <div className="flex flex-col justify-between dhmin:max-dh:text-[25px] w-[20%] pv:max-ph:w-full ph:max-md:w-[30%] md:max-xl:w-full gap-2">
          <a href="#" className="uppercase font-bold">
            Điều khoản
          </a>
          <div>
            Điều khoản <br /> Bảo mật thông tin
          </div>
          <div className="uppercase font-bold flex flex-col gap-2">
            <a href="">Kết nối với chúng tôi</a>
            <div className="flex space-x-2">
              <div className="rounded-full bg-white p-[6px]">
                <FaFacebook color="#142548" size={25} />
              </div>
              <div className="rounded-full bg-white p-[6px]">
                <FaInstagram color="#142548" size={25} />
              </div>
              <div className="rounded-full bg-white p-[6px]">
                <FaYoutube color="#142548" size={25} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
