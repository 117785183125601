import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Common/header";
import Footer from "../../components/Common/footer";
import PackageRadioButton from "./packageRadioButton";
import OrderSummary from "../../components/Common/payment_components/orderSumary";

function AddToCart() {
  const [selectedPackage, setSelectedPackage] = useState({
    value: 2, // Default selected value
    label: "Gói 3 tháng",
    price: 499000,
  });

  const discount1 = 100000;
  const discount2 = 50000;

  // const packagePrice = parseInt(selectedPackage.price.replace(".", ""));
  const packagePrice = selectedPackage.price;

  const totalPrice = packagePrice - discount1 - discount2;

  const handlePackageChange = (value: number, label: string, price: number) => {
    setSelectedPackage({ value, label, price });
  };

  const navigate = useNavigate();

  const handlePayment = () => {
    navigate("/payment", {
      state: {
        selectedPackage,
        discount1,
        discount2,
        totalPrice,
      },
    });
  };

  return (
    <div className="bg-[#F8F8F8] w-full">
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="flex justify-center pt-40">
        <div className="w-[95%] lg:w-[96%] dhmin:w-[98%] dv:w-[95%] dvmax:dh:w-[98%] h-[45rem] mb-5">
          <div className="flex h-full w-full justify-between">
            {/* left section */}
            <div className="w-[75%] bg-white rounded-[30px] p-10">
              <div className="gap-6 flex flex-col">
                <p className="font-bold text-3xl text-[#142548]">Gói đã chọn</p>
                <PackageRadioButton
                  selectedValue={selectedPackage.value}
                  onChange={handlePackageChange}
                />
                <p className="font-bold text-3xl text-[#142548]">
                  Mã khuyến mãi
                </p>
                <div className="flex justify-between border border-[#142548] rounded-full px-4 py-2">
                  <input className="w-3/4" placeholder="ABCD"></input>
                  <button className="bg-[#142548] font-semibold py-1 px-3 rounded-[25px] text-white">
                    <p> Áp dụng</p>
                  </button>
                </div>
              </div>
            </div>
            {/* right section */}
            <div className="w-[23%] bg-white rounded-[30px] p-10">
              <OrderSummary
                selectedPackage={selectedPackage}
                discount1={discount1}
                discount2={discount2}
                totalPrice={totalPrice}
                handlePayment={handlePayment}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddToCart;
