import { CSSProperties, useEffect } from "react";
import {
  culture_lessons,
  math_lessons,
  skill_lessons,
  vietnamese_lessons,
} from "./mocks";
import Header from "../../components/Common/header";

function ForSchoolPage() {
  useEffect(() => {
    var video = document.getElementById("myvideo") as HTMLVideoElement;
    video.addEventListener("contextmenu", handlecontextmenu);
    return () => {
      document.removeEventListener("contextmenu", handlecontextmenu);
    };
  }, []);

  const handlecontextmenu = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div className=" flex flex-col bg-[#F9FEFF]">
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div
        className="flex flex-col items-center relative pt-[40px]"
        style={{
          backgroundImage: `url("/background/bg_for_school_repeat.png")`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "contain",
        }}
      >
        <div className="h-[100px] flex justify-center" />
        <div className="flex flex-row justify-center h-[200px] ">
          <img
            className="min-w-[80%] h-full object-contain"
            src="/images/mark/school_mark.png"
          />
        </div>
        {/* CONTENT */}
        <div className="w-full flex justify-center">
          <div className="flex text-white font-bold mt-10 uppercase justify-between w-[80%]">
            <div className="bg-[#65AFFF] px-20 py-3 rounded-[35px]">
              Tiếng việt
            </div>
            <div className="bg-[#65AFFF] px-20 py-3 rounded-[35px]">Toán</div>

            <div className="bg-[#65AFFF] px-20 py-3 rounded-[35px]">
              Văn hoá dân gian
            </div>
            <div className="bg-[#65AFFF] px-20 py-3 rounded-[35px]">
              Kỹ năng xã hội
            </div>
            <div className="bg-[#65AFFF] px-20 py-3 rounded-[35px]">
              Tìm kiếm học liệu
            </div>
          </div>
        </div>
        <div className=" flex flex-row justify-center mt-10 py-1 bg-w">
          <table
            style={{
              width: "80%",
              borderCollapse: "collapse",
              margin: "20px 0",
              fontSize: "16px",
              textAlign: "left",
              // borderRadius:'30px'
            }}
            className=""
          >
            <thead>
              <tr>
                <TableHeader title="Đảo" />
                <TableHeader title="Bài học" />
                <TableHeader title="Hoạt động hỗ trợ" />
                <TableHeader title="Độ tuổi" />
                <TableHeader title="Mục nội dung khung BGD" />
                <TableHeader title="Mục tiêu" />
              </tr>
            </thead>
            <tbody>
              {vietnamese_lessons.map((lesson, index) => {
                return (
                  <tr key={index}>
                    {index == 0 && (
                      <TableDataCell
                        bgColor="#FFF9DA"
                        rowSpan={vietnamese_lessons.length}
                        style={{ textAlign: "center" }}
                      >
                        <span className="text-[#FFA500] text-[40px] font-bold">
                          TIẾNG VIỆT
                        </span>
                      </TableDataCell>
                    )}
                    <TableDataCell>
                      <div className="flex flex-col items-center">
                        <h5 className="text-[20px] text-[#143A87] font-bold mb-4">
                          {lesson.lessonTitle}
                        </h5>
                        {lesson.videoUrl ? (
                          <video
                            id="myvideo"
                            className="max-w-[250px] rounded-[9px]"
                            controls
                            controlsList="nodownload"
                          >
                            <source src={lesson.videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className="object-contain rounded-[9px]"
                            src={`/images/tiengviet/lesson/image-${index}.png`}
                          />
                        )}
                      </div>
                    </TableDataCell>
                    <TableDataCell style={{ verticalAlign: "bottom" }}>
                      <div className="flex flex-col justify-between w-full h-full">
                        <span className="h-[50px]"></span>
                        <img
                          className="object-contain"
                          src={`/images/tiengviet/active/image-${index}.png`}
                        />
                      </div>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[#65AFFF] text-[24px] font-bold p-1 text-center">
                        {lesson.age}
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[20px] font-bold text-[#143A87] p-4 text-center">
                        {lesson.contentTitle}
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[20px] font-bold text-[#143A87] p-4 text-center">
                        {lesson.target}
                      </span>
                    </TableDataCell>
                  </tr>
                );
              })}
              <tr>
                {Array.from({ length: 6 }, (_, index) => (
                  <TableDataCell key={index}>
                    <div className="min-h-[20px]" />
                  </TableDataCell>
                ))}
              </tr>
              {math_lessons.map((lesson, index) => {
                return (
                  <tr key={index}>
                    {index == 0 && (
                      <TableDataCell
                        bgColor="#E4FAFF"
                        rowSpan={math_lessons.length}
                        style={{ textAlign: "center" }}
                      >
                        <span className="text-[#027CFF] text-[40px] font-bold">
                          TOÁN HỌC
                        </span>
                      </TableDataCell>
                    )}
                    <TableDataCell style={{ verticalAlign: "bottom" }}>
                      <div className="flex flex-col items-center">
                        <h5 className="text-[20px] text-[#143A87] font-bold mb-4">
                          {lesson.lessonTitle}
                        </h5>
                        {lesson.videoUrl ? (
                          <video
                            id="myvideo"
                            className="max-w-[250px] rounded-[9px]"
                            controls
                            controlsList="nodownload"
                          >
                            <source src={lesson.videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className="object-contain bg-purple-400"
                            src={`/images/tiengviet/lesson/image-${index}.png`}
                          />
                        )}
                      </div>
                    </TableDataCell>
                    <TableDataCell style={{}}>
                      <div className="flex flex-col justify-between w-full h-full">
                        <span className="h-[50px]"></span>
                        <img
                          className="object-contain"
                          src={`/images/tiengviet/active/image-${index}.png`}
                        />
                      </div>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[#65AFFF] text-[24px] font-bold p-1 text-center">
                        {lesson.age}
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[20px] font-bold text-[#143A87] p-4 text-center">
                        {lesson.contentTitle}
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[20px] font-bold text-[#143A87] p-4 text-center">
                        {lesson.target}
                      </span>
                    </TableDataCell>
                  </tr>
                );
              })}
              <tr>
                {Array.from({ length: 6 }, (_, index) => (
                  <TableDataCell key={index}>
                    <div className="min-h-[20px]" />
                  </TableDataCell>
                ))}
              </tr>
              {culture_lessons.map((lesson, index) => {
                return (
                  <tr key={index}>
                    {index == 0 && (
                      <TableDataCell
                        bgColor="#FBEAE9"
                        rowSpan={culture_lessons.length}
                        style={{ textAlign: "center" }}
                      >
                        <span className="text-[#F56177] text-[40px] font-bold">
                          VĂN HÓA DÂN GIAN
                        </span>
                      </TableDataCell>
                    )}
                    <TableDataCell>
                      <div className="flex flex-col items-center">
                        <h5 className="text-[20px] text-[#143A87] font-bold mb-4">
                          {lesson.lessonTitle}
                        </h5>
                        {lesson.videoUrl ? (
                          <video
                            id="myvideo"
                            controls
                            controlsList="nodownload"
                          >
                            <source src={lesson.videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className="object-contain"
                            src={`/images/tiengviet/lesson/image-${index}.png`}
                          />
                        )}
                      </div>
                    </TableDataCell>
                    <TableDataCell style={{ verticalAlign: "bottom" }}>
                      <div className="flex flex-col justify-between w-full h-full">
                        <span className="h-[50px]"></span>
                        <img
                          className="object-contain"
                          src={`/images/tiengviet/active/image-${index}.png`}
                        />
                      </div>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[#65AFFF] text-[24px] font-bold p-1 text-center">
                        {lesson.age}
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[20px] font-bold text-[#143A87] p-4 text-center">
                        {lesson.contentTitle}
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[20px] font-bold text-[#143A87] p-4 text-center">
                        {lesson.target}
                      </span>
                    </TableDataCell>
                  </tr>
                );
              })}
              <tr>
                {Array.from({ length: 6 }, (_, index) => (
                  <TableDataCell key={index}>
                    <div className="min-h-[20px]" />
                  </TableDataCell>
                ))}
              </tr>
              {skill_lessons.map((lesson, index) => {
                return (
                  <tr key={index}>
                    {index == 0 && (
                      <TableDataCell
                        bgColor="#B8C4FD"
                        rowSpan={skill_lessons.length}
                        style={{ textAlign: "center" }}
                      >
                        <span
                          className="text-[#142548] text-[40px] font-bold"
                          style={{ textAlign: "center" }}
                        >
                          KỸ NĂNG
                        </span>
                      </TableDataCell>
                    )}
                    <TableDataCell>
                      <div className="flex flex-col items-center">
                        <h5 className="text-[20px] text-[#143A87] font-bold mb-4">
                          {lesson.lessonTitle}
                        </h5>
                        <img
                          className="object-contain"
                          src={`/images/tiengviet/lesson/image-${index}.png`}
                        />
                      </div>
                    </TableDataCell>
                    <TableDataCell style={{ verticalAlign: "bottom" }}>
                      <div className="flex flex-col justify-between w-full h-full">
                        <span className="h-[50px]"></span>
                        <img
                          className="object-contain"
                          src={`/images/tiengviet/active/image-${index}.png`}
                        />
                      </div>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[#65AFFF] text-[24px] font-bold p-1 text-center">
                        {lesson.age}
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[20px] font-bold text-[#143A87] p-4 text-center">
                        {lesson.contentTitle}
                      </span>
                    </TableDataCell>
                    <TableDataCell>
                      <span className="text-[20px] font-bold text-[#143A87] p-4 text-center">
                        {lesson.target}
                      </span>
                    </TableDataCell>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div
          className="w-full h-[700px] relative mt-[-100px] z-[-1]"
          style={{
            background: `url("/background/bg_cay_da_san_dinh.svg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        ></div>
        <img
          className=" w-full object-cover"
          src="img_slider/img3.png"
          alt=""
        />
      </div>
    </div>
  );
}

function TableHeader(props: { title?: string }) {
  return (
    <th
      className="bg-[#65AFFF] text-white text-center align-bottom px-3 py-6"
      style={{ border: "#142548 1px solid" }}
    >
      {props.title}
    </th>
  );
}

function TableDataCell(props: {
  children?: React.ReactNode;
  bgColor?: string;
  rowSpan?: number;
  style?: CSSProperties;
}) {
  return (
    <td
      style={{
        ...props.style,
        border: "1px solid #ddd",
        padding: "8px",
        background: props.bgColor ?? "white",
        minHeight: 50,
      }}
      rowSpan={props.rowSpan}
    >
      {props.children}
    </td>
  );
}
export default ForSchoolPage;
