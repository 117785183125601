interface TransactionProps {
  dateTime: string;
  kindOfPackage: string;
}

const Transaction: React.FC<TransactionProps> = ({
  dateTime,
  kindOfPackage,
}) => {
  return (
    <div className="px-20 pb-2 mb-4 bg-white flex justify-between border-b-2 border-dashed border-[#65AFFF] text-xl font-medium">
      <p>{dateTime}</p>
      <p>{kindOfPackage}</p>
    </div>
  );
};

export default Transaction;
