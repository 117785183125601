import React from "react";

interface Package {
  value: number;
  label: string;
  price: number;
}

interface OrderSummaryProps {
  selectedPackage: Package;
  discount1: number;
  discount2: number;
  totalPrice: number;
  // isDisplayButton: boolean;
  handlePayment?: () => void;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  selectedPackage,
  discount1,
  discount2,
  totalPrice,
  // isDisplayButton,

  handlePayment,
}) => {
  return (
    <div className="gap-6 flex flex-col">
      <p className="font-bold text-3xl text-[#142548]">Đơn hàng</p>
      <div>
        <div className="w-full flex justify-between">
          <p className="font-bold text-lg text-[#142548]">
            {selectedPackage.label}
          </p>
          <p className="font-medium text-lg text-[#142548]">
            {selectedPackage.price.toLocaleString("de-DE")}
          </p>
        </div>
        <div className="h-[1px] border-t border-dashed border-[#65AFFF]" />
      </div>
      <div>
        <p className="font-bold text-lg text-[#142548]">Giảm giá</p>
        <div className="w-full flex justify-between">
          <p className="font-medium text-lg text-[#142548]">Mã ABCXYZ</p>
          <p className="font-medium text-lg text-[#F56177]">-100.000</p>
        </div>
        <div className="w-full flex justify-between">
          <p className="font-medium text-lg text-[#142548]">Ưu đãi 1</p>
          <p className="font-medium text-lg text-[#F56177]">-50.000</p>
        </div>
        <div className="h-[1px] border-t border-dashed border-[#65AFFF]" />
      </div>

      {/* Total amount after discounts */}
      <div className="w-full flex justify-between align-bottom items-end">
        <p className="font-bold text-3xl text-[#142548]">Tổng cộng</p>
        <p className="font-medium text-lg text-[#142548]">
          {totalPrice.toLocaleString("vi-VN")}
        </p>
      </div>

      {handlePayment && (
        <div className="flex justify-end">
          <button
            className="bg-[#142548] w-fit font-semibold py-1 px-3 rounded-[25px] text-white"
            onClick={handlePayment}
          >
            <p>Thanh toán</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
