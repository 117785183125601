interface ButtonProps {
  children: React.ReactNode;
  active?: string;
  full?: boolean;
  primary?: boolean;
  className?: any;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  full = false,
  primary = false,
  active = "inactive",
  onClick,
  className,
  children,
}) => {
  return (
    <p
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className={`${
        full ? "w-full" : "w-fit"
      } px-6 py-2 text-sm rounded-full cursor-pointer hover:opacity-70 transition-opacity duration-300 ${
        active === "active"
          ? "bg-white border-2 border-default text-default"
          : " text-white"
      } ${primary ? "bg-[#F56177]" : "bg-default"}  ${className}`}
    >
      {children}
    </p>
  );
};

export default Button;
