import React from "react";
import classNames from "classnames";

interface serviceCardProps {
  packageType: string;
  packageColor: string;
  price: number;
  imageUrl: string;
  pricePerMonth: number;
  isExpanded: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
}

function ServiceCard({
  packageType,
  packageColor,
  imageUrl,
  price,
  pricePerMonth,
  isExpanded,
  onClick,
  onDoubleClick,
}: serviceCardProps) {
  return (
    <div onDoubleClick={onDoubleClick}>
      {packageType === "6" && (
        <div className="px-6">
          <div className="bg-white rounded-t-[12px] pt-2 font-semibold dvmax:max-dhmin:text-xl text-xs text-center text-[#047EAA]">
            <p>Miễn phí 1 tháng đăng kí</p>
          </div>
        </div>
      )}
      <div
        className={classNames(
          "flex flex-col justify-between items-center p-4 rounded-[30px] w-60 dvmax:max-dhmin:w-[300px] shadow-lg transition-all duration-300 cursor-pointer relative",
          {
            "h-[400px] dvmax:max-dhmin:h-[480px]": isExpanded,
            "h-[300px] dvmax:max-dhmin:h-[400px]": !isExpanded,
          }
        )}
        style={{ backgroundColor: packageColor }}
        onClick={onClick}
        aria-expanded={isExpanded}
      >
        <p className="font-bold text-[20px] dvmax:max-dhmin:text-[27px] text-white mb-2 uppercase">
          Gói {packageType} tháng
        </p>
        <div
          className="relative bg-white text-4xl dvmax:max-dhmin:text-[55px] dvmax:max-dhmin:py-5 font-bold rounded-r-full px-4 py-2 w-44 dvmax:max-dhmin:w-[250px] dvmax:max-dhmin:-left-[25px] text-center -left-8 shadow-md"
          style={{ color: packageColor }}
        >
          {price.toLocaleString("de-DE")}
          {packageType === "12" && (
            <div className="text-xs dvmax:max-dhmin:text-[18px] px-3 font-normal text-end flex justify-end gap-1">
              <p> Giá gốc: </p>
              <p className="line-through">1.299.000</p>
            </div>
          )}
        </div>
        <img
          src={`character_service_package/${imageUrl}.png`}
          alt={`service_package_${imageUrl}`}
          className="w-24 dvmax:max-dhmin:w-[120px] object-contain"
        />
        <div className="flex gap-1 items-end">
          <p className="font-bold text-xl dvmax:max-dhmin:text-[20px] text-white">
            ~{pricePerMonth.toLocaleString("de-DE")}
          </p>
          <p className="font-bold text-xl dvmax:max-dhmin:text-[20px] text-white">
            /
          </p>
          <p className="font-medium text-lg dvmax:max-dhmin:text-[17px] text-white">1 tháng</p>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
