import React, { useState } from "react";
import { Radio, Space } from "antd";
import type { RadioChangeEvent } from "antd";

const OptionPaymentRadioButton: React.FC = () => {
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const paymentMethods = [
    { id: 1, label: "VNPAY" },
    { id: 2, label: "Phương thức 2" },
    { id: 3, label: "Phương thức 3" },
    { id: 4, label: "Phương thức 4" },
  ];

  return (
    <Radio.Group onChange={onChange} value={value} className="w-full">
      <Space direction="vertical" className="w-full">
        {paymentMethods.map((method) => (
          <Radio
            key={method.id}
            value={method.id}
            className="custom-radio border border-[#142548] w-full rounded-[34px] p-2"
          >
            <div className="flex justify-between font-semibold w-full text-base text-[#142548]">
              <p>{method.label}</p>
            </div>
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default OptionPaymentRadioButton;
