import React, { useState } from "react";
import { LuUser2 } from "react-icons/lu";
import { RiUserLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import About from "./about_card";
import HeaderMobile from "./headerMobile";

interface HeaderProps {
  searchTextChangeColor?: boolean;
}

function Header({ searchTextChangeColor = false }: HeaderProps) {
  const [showAbout, setShowAbout] = useState(false);
  const [titleAbout, setTitleAbout] = useState("");
  const [contentAbout, setContentAbout] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMouseEnter = (title: string, content: string) => {
    setTitleAbout(title);
    setContentAbout(content);
    setShowAbout(true);
  };

  const handleMouseLeave = () => {
    setShowAbout(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div>
      {/* header for device 280-768 */}
      <div className="lg:max-dh:hidden">
        <HeaderMobile />
      </div>

      {/* header for device 768-5000 */}
      <div className="relative pv:max-lg:hidden">
        <div className="flex justify-center py-4">
          <div className="w-[95%] lg:w-[96%] dvmax:w-[98%] dv:w-[95%] dvmax:dh:w-[98%]">
            {/* Search Section */}
            <div className="flex justify-end gap-4 mb-3 pr-16">
              <div className="flex items-start gap-1">
                <img
                  src={`${
                    searchTextChangeColor
                      ? "logo/search_dark.png"
                      : "logo/search.png"
                  } `}
                  className="w-[20px] h-[20px] dvmax:max-dh:w-[25px] dvmax:max-dh:h-[25px] object-contain"
                  alt=""
                />
                <div
                  className={`text-lg dvmax:max-dh:text-[20px] text-end h-[25px] font-bold  ${
                    searchTextChangeColor ? "text-[#142548]" : "text-white"
                  } `}
                >
                  Tìm kiếm
                </div>
              </div>
            </div>
            {/* Main Header Section */}
            <div className="bg-white rounded-[33.5px] dvmax:max-dh:rounded-[40px] dvmax:max-dh:px-10  w-full px-4 py-5 md:px-6 flex pvmax:max-xlmax:flex-col pvmax:max-xlmax:py-4 justify-between items-center pv:max-xlmax:gap-4 pv:max-xl:p-4">
              {/* Left Links */}
              <ul className="font-bold text-lg dvmax:max-dh:text-[22px] text-[#142548] lg:max-xlmax:space-x-32 flex gap-4 md:space-y-0 md:space-x-10 ">
                <li>
                  <a href="/">Trang chủ</a>
                </li>
                <li
                  onMouseEnter={() =>
                    handleMouseEnter(
                      "Về chúng tôi",
                      "Khơi dậy tiềm năng qua những giải pháp giáo dục số, Whycorp cam kết kiến tạo một tương lai nơi học tập trở thành hành trình đầy cảm hứng và kết nối sâu sắc. Chúng tôi đem đến những trải nghiệm học tập hiện đại, nơi truyền thống và công nghệ hòa quyện để phát triển thế hệ trẻ một cách toàn diện."
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                >
                  <a href="#" className="hover:text-[#F56177]">
                    Về chúng tôi
                  </a>
                </li>
                <li
                  onMouseEnter={() =>
                    handleMouseEnter(
                      "Về WhyKids",
                      "Whykids mở ra một thế giới giáo dục số hóa sống động, nơi trẻ em khám phá kiến thức thông qua các bài học giàu bản sắc Việt Nam. Ứng dụng không chỉ nuôi dưỡng sự phát triển toàn diện mà còn tạo nên mối liên kết bền chặt giữa gia đình, nhà trường và trẻ."
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                >
                  <a href="#" className="hover:text-[#F56177]">
                    Về WhyKids
                  </a>
                </li>
                <li>
                  <a href="/service-package">
                    Gói dịch vụ
                  </a>
                </li>
              </ul>
              {/* Logo */}
              <div className="my-4 md:my-0 pv:max-xlmax:hidden">
                <div className="bg-contain w-[80px] h-[48px] dvmax:max-dh:w-full dvmax:max-dh:h-full items-center flex justify-center">
                  <a href="/">
                    <img src="logo/logo_header.png" alt="Logo" />
                  </a>
                </div>
              </div>
              {/* Right Links */}
              <ul className="font-bold text-lg  dvmax:max-dh:text-[22px] text-[#142548] flex items-center gap-4 lg:max-xlmax:space-x-32 md:space-y-0 md:space-x-10">
                <li>
                  <a href="/forschool">Thư viện học liệu</a>
                </li>
                <li>
                  <a href="#">Blog/ Tin tức</a>
                </li>
                <li>
                  <a href="#">Hỗ trợ</a>
                </li>
                <li className="">
                  <Link to="/login">
                    <div className="rounded-[30px] bg-[#F56177] text-white flex py-2 space-x-2 items-center px-5">
                      <LuUser2 className="font-bold dvmax:max-dh:text-[22px]" />
                      <span> Tài khoản</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Show About Component */}
        <div className=" xl:px-16 px-8 md:px-6 pv:max-md:hidden ">
          {showAbout && (
            <About titleAbout={titleAbout} contentAbout={contentAbout} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
