import React, { useEffect } from "react";
import CarouselHeader from "../../components/Common/home_components/carousel";
import Devider from "../../components/Common/devider";
import IslandInfo from "../../components/Common/home_components/island";
import HomeCard from "../../components/Common/home_components/homecard";
import BodyCarousel from "../../components/Common/home_components/body_carousel";
import Footer from "../../components/Common/footer";
import CharacterCarousel from "../../components/Common/home_components/character_carousel";
import { FloatButton } from "antd";
import FloatingButton from "../../components/Common/home_components/floatingButton";

function HomePage() {
  useEffect(() => {
    var video = document.getElementById("myvideo") as HTMLVideoElement;
    video.addEventListener("contextmenu", handlecontextmenu);
    return () => {
      document.removeEventListener("contextmenu", handlecontextmenu);
    };
  }, []);
  const handlecontextmenu = (e: MouseEvent) => {
    e.preventDefault();
    // alert('Right click disable');
  };
  return (
    <div className="">
      <CarouselHeader />
      {/* <div className="bg-white flex justify-around my-6">
        <RewardCard rewardName="abc" />
        <RewardCard rewardName="abc" />
        <RewardCard rewardName="abc" />
      </div>
     */}
      <IslandInfo />
      <div className="items-center py-16 pv:max-ph:px-5 pv:max-ph:py-10 flex flex-col justify-center">
        <div className="xl:max-dh:w-[40%] md:max-xl:w-[70%] flex flex-col justify-center items-center">
          <p className="uppercase font-bold text-[#142548] dvmax:max-dh:text-[120px] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-dh:text-[60px]">
            Thuần việt
          </p>
          <p className="text-[20px] sm:max-md:text-xl md:max-lg:text-[14px] text-justify font-[500] dvmax:max-dh:text-[30px] text-[#142548] px-5 ">
            Whykids giúp trẻ tập trung phát triển ngôn ngữ tiếng Việt, hiểu biết
            sâu sắc về văn hóa Việt Nam, cùng với hình ảnh đồ họa đậm chất Việt,
            tạo nên một trải nghiệm học tập thuần Việt độc đáo.
          </p>
        </div>
      </div>
      <BodyCarousel />
      <div className="mt-10"></div>
      <HomeCard
        urlLink="logo/content.png"
        title="nội dung độc quyền"
        content="Kho tàng video và bài hát độc quyền của Whykids không chỉ mang đậm dấu ấn thương hiệu với nội dung giáo dục sáng tạo và an toàn, mà còn giúp trẻ học tập hiệu quả, ghi nhớ lâu dài, và phát triển toàn diện thông qua giai điệu và tình huống hấp dẫn."
        reverseRow={true}
      />
      <HomeCard
        urlLink="https://storage.googleapis.com/why_kid_static_content/WEBSITE/Trailer_Game.mp4"
        title="trải nghiệm số hóa"
        content="Với trải nghiệm học tập đa dạng và cá nhân hóa, Whykids khiến việc học trở nên thú vị hơn bao giờ hết, giúp trẻ em hứng thú và gắn bó lâu dài trong hành trình khám phá kiến thức."
        reverseRow={false}
      />
      <CharacterCarousel
        // urlLink="character/tac_ke.png"
        title="HỆ THỐNG NHÂN VẬT ĐẶC BIỆT"
        content="Những nhân vật trong thế giới Whykids không chỉ mang lại niềm vui mà còn là những người bạn đồng hành trong hành trình giáo dục, với tính cách gần gũi, bối cảnh Việt Nam thân thuộc, và những câu chuyện gắn liền với cuộc sống hàng ngày của trẻ."
        reverseRow={true}
      />
      <Footer />
      <FloatingButton />
    </div>
  );
}

export default HomePage;
