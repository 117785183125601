import React from "react";
import Header from "../../components/Common/header";
import Footer from "../../components/Common/footer";
import backgroundImg from "../../assets/login/bg_login.png";
import LayoutServiceCard from "./layoutServiceCard";

function ServicePackage() {
  return (
    <div
      className="w-[100%] relative"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
        <div className="absolute z-50 w-full">
          <Header />
        </div>
      <div className="pt-48">
        <LayoutServiceCard />
      </div>
      <Footer />
    </div>
  );
}

export default ServicePackage;
