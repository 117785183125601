import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Common/header";
import Footer from "../../components/Common/footer";
import OptionPaymentRadioButton from "./optionPaymentRadioButton";
import OrderSummary from "../../components/Common/payment_components/orderSumary";

function Payment() {
  const location = useLocation();
  const { selectedPackage, discount1, discount2, totalPrice } =
    location.state || {};

  return (
    <div className="bg-[#F8F8F8] w-full">
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="flex justify-center pt-40 ">
        <div className="w-[95%] lg:w-[96%] dhmin:w-[98%] dv:w-[95%] dvmax:dh:w-[98%] h-[45rem] mb-5">
          <div className="flex h-full w-full justify-between">
            {/* left section */}
            <div className="w-[75%] bg-white rounded-[30px] p-10">
              <div className="gap-6 flex flex-col">
                <p className="font-bold text-3xl text-[#142548]">
                  Thông tin liên hệ
                </p>
                <div>
                  <p className="font-semibold text-lg text-[#142548]">
                    Họ và tên
                  </p>
                  <input
                    className="w-full h-12 bg-[#9DCBFC] rounded-full mt-1 sm:mt-3 px-4 text-[#FFFFFF] font-semibold"
                    placeholder="Họ và tên"
                  ></input>
                </div>
                <div>
                  <p className="font-semibold text-lg text-[#142548]">
                    Số điện thoại
                  </p>
                  <input
                    className="w-full h-12 bg-[#9DCBFC] rounded-full mt-1 sm:mt-3 px-4 text-[#FFFFFF] font-semibold"
                    placeholder="Số điện thoại"
                  ></input>
                </div>
                <p className="font-bold text-3xl text-[#142548]">
                  Phương thức thanh toán
                </p>
                <OptionPaymentRadioButton />
                <div className="flex justify-end">
                  <button className="bg-[#142548] w-fit font-semibold py-1 px-3 rounded-[25px] text-white">
                    <p> Xác nhận</p>
                  </button>
                </div>
              </div>
            </div>
            {/* right section */}
            <div className="w-[23%] bg-white rounded-[30px] p-10">
              <OrderSummary
                selectedPackage={selectedPackage}
                discount1={discount1}
                discount2={discount2}
                totalPrice={totalPrice}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Payment;
