import accountBaby from "../../assets/Account/accountBaby.png";
import backgroundClouds from "../../assets/Account/backgroundClouds.png";

import { Button } from "../../components/Common";

const Banner = () => {
  return (
    <div className="relative bg-[#9FD356] mb-8 w-full h-[280px] rounded-3xl lg:h-[400px]">
      <div className="absolute top-12 left-6 lg:left-20 lg:top-28">
        <p className="uppercase text-white text-xl font-bold lg:text-3xl">
          {"BÉ ĐANG DÙNG GÓI 6 THÁNG"}
        </p>
        <p className="text-white text-sm font-normal lg:text-xl">
          {"(từ ngày 29/03/2024 - ngày 29/09/2024)"}
        </p>
      </div>
      <div className="absolute bottom-[-8px] right-0">
        <img alt="" src={backgroundClouds} className="w-full object-cover" />
      </div>
      <div className="absolute right-[-45px] bottom-[-60px] lg:bottom-0 lg:right-10">
        <img
          alt=""
          src={accountBaby}
          className="object-fit scale-50 lg:scale-100"
        />
      </div>
      <Button
        primary
        className="absolute left-5 bottom-14 lg:bottom-10 lg:left-20"
      >
        <p className="px-6 text-base font-bold">{"Nâng cấp"}</p>
      </Button>
    </div>
  );
};

export default Banner;
