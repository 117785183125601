import { title } from "process";
import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";

const headerInfo = [
  { title: 'Trang chủ', path: '/' },
  { title: 'Về chúng tôi', path: '/' },
  { title: 'Về Whykids', path: '/' },
  { title: 'Gói dịch vụ', path: '/service-package' },
  { title: 'Thư viện học liệu', path: '/forschool' },
  { title: 'Blog/ Sự kiện', path: '/' },
  { title: 'Hỗ trợ', path: '/' }



]


function HeaderMobile() {
  const [isMenuVisible, setMenuVisible] = useState(false);

  // Function to toggle the menu visibility
  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible); // Toggles the state between true and false
  };

  return (
    <div className="p-10">
      <div className="w-full flex justify-between bg-white py-2 px-10 pv:max-ph:px-6 rounded-[40px]">
        <a href="#" className="flex items-center">
          <img
            src="logo/menu_logo.png"
            className="h-12 pv:max-ph:h-6 object-contain"
            alt="menu logo"
            onClick={toggleMenu} // Toggle the menu on click
          />
        </a>
        <a href="/" className="flex items-center">
          <img
            src="logo/logo_header.png"
            className="h-14 pv:max-ph:h-7 object-contain"
            alt="WhyKids Logo"
          />
        </a>
        <a href="/login" className="flex items-center">
          <img
            src="logo/account.png"
            className="h-12 pv:max-ph:h-6 object-contain"
            alt="Account Logo"
          />
        </a>
      </div>

      {/* Conditionally render the menu based on the state */}
      {isMenuVisible && (
        <div className="w-full bg-white rounded-[30px] py-6 px-10 mt-3">
          <ul className="gap-1 font-bold text-lg text-[#142548]">
            {headerInfo.map((info, index) => (
              <li className="p-1">
                <a href={info.path} className="flex items-center justify-start">
                  <p className="normal-case">{info.title}</p>
                  <MdArrowForwardIos className="ml-6" />
                </a>
              </li>
            ))}

          </ul>
        </div>
      )}
    </div>
  );
}

export default HeaderMobile;
