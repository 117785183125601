import React from "react";
import { Carousel } from "antd";

const imageList = [
  "img_slider/img1.png",
  "img_slider/img2.png",
  "img_slider/img3.png",
  "img_slider/img4.png",
  "img_slider/img5.png",
  "img_slider/img6.png",
  "img_slider/img7.jpg",
  "img_slider/img8.jpg",
];

const BodyCarousel: React.FC = () => {
  return (
    <Carousel
      autoplay
      speed={100}
      lazyLoad="progressive"
      effect="fade"
      fade={true}
      dots={false}
      className="w-full px-20 pv:max-md:px-5"
    >
      {imageList.map((imageSrc, index) => (
        <div
          key={index}
          className="rounded-[30px] h-[auto] object-cover bg-red-400"
        >
          <img
            src={imageSrc}
            alt={`Slide ${index + 1}`}
            className="w-full h-full rounded-[30px] bg-red-300"
          />
        </div>
      ))}
    </Carousel>
  );
};

export default BodyCarousel;
