import { Transaction } from "../Account";

interface TransactionHistoryProps {
  transactions:
    | Array<{
        dateTime: string;
        kindOfPackage: string;
      }>
    | [];
}

const TransactionHistory: React.FC<TransactionHistoryProps> = ({
  transactions,
}) => {
  const renderListOfTransactions = () => {
    return transactions.map((transaction, index) => {
      return (
        <Transaction
          key={index}
          dateTime={transaction.dateTime}
          kindOfPackage={transaction.kindOfPackage}
        />
      );
    });
  };

  return (
    <div className="py-12 bg-white rounded-3xl animate-appear">
      <p className="mb-10 px-20 uppercase text-3xl font-bold">
        {"LỊCH SỬ GIAO DỊCH"}
      </p>
      {transactions && transactions.length > 0 ? (
        renderListOfTransactions()
      ) : (
        <p className="text-xl text-slate-400">
          {"Bạn chưa thực hiện giao dịch nào"}
        </p>
      )}
    </div>
  );
};

export default TransactionHistory;
