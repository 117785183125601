import React from "react";
import type { RadioChangeEvent } from "antd";
import { Radio, Space } from "antd";

interface PackageOption {
  value: number;
  label: string;
  price: number;
}

interface PackageRadioButtonProps {
  selectedValue: number;
  onChange: (value: number, label: string, price: number) => void;
}

const packageOptions: PackageOption[] = [
  { value: 1, label: "Gói 1 tháng", price: 199000 },
  { value: 2, label: "Gói 3 tháng", price: 499000 },
  { value: 3, label: "Gói 6 tháng", price: 899000},
  { value: 4, label: "Gói 12 tháng", price: 1199000 },
];

const PackageRadioButton: React.FC<PackageRadioButtonProps> = ({
  selectedValue,
  onChange,
}) => {
  const handleChange = (e: RadioChangeEvent) => {
    const selectedPackage = packageOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedPackage) {
      onChange(selectedPackage.value, selectedPackage.label, selectedPackage.price);
    }
  };

  return (
    <div>
      <Radio.Group onChange={handleChange} value={selectedValue} className="w-full">
        <Space direction="vertical" className="w-full">
          {packageOptions.map((option) => (
            <Radio
              key={option.value}
              value={option.value}
              className="custom-radio border border-[#142548] w-full rounded-[34px] p-2"
            >
              <div className="flex justify-between font-semibold w-full text-base text-[#142548]">
                <p className="mr-10">{option.label}</p>
                <p className="">{option.price.toLocaleString("de-DE")}</p>
              </div>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );
};

export default PackageRadioButton;
