import { Carousel } from "antd";
import React from "react";

const characterList = [
  "character_gif/ca_voi.gif",
  "character_gif/cu.gif",
  "character_gif/lung.gif",
  "character_gif/nghe.gif",
  "character_gif/TK.gif",
];

interface CharacterCardProps {
  title: string;
  content: string;
  reverseRow: boolean;
}

function CharacterCarousel({
  title,
  content,
  reverseRow = false,
}: CharacterCardProps) {
  return (
    <div
      className={`w-full px-20 py-16 pv:max-md:px-5 pv:max-md:py-10 justify-between flex ${
        reverseRow ? "flex-row" : "flex-row-reverse"
      } pv:max-md:flex-col-reverse pv:max-md:items-center`}
    >
      <div className="w-[38%] md:max-xl:h-[80vh] dvmax:max-dh:h-[50vh] pv:max-md:w-full place-content-end">
        <Carousel
          autoplay
          speed={100}
          lazyLoad="progressive"
          effect="fade"
          fade={true}
          dots={false}
          className="w-full min-h-fit"
        >
          {characterList.map((imageSrc, index) => (
            <div key={index} className="h-full flex items-end">
              <img
                src={imageSrc}
                alt={`Slide ${index + 1}`}
                className="w-full object-contain"
              />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="flex flex-col justify-end w-[60%] pv:max-md:w-full pv:max-md:text-center">
        <p className="uppercase font-[800] dvmax:max-dh:text-[105px] tracking-wide text-[#142548] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-dh:text-[60px]">
          {/* {title} */}
          Hệ Thống <br />
          nhân vật đặc biệt
        </p>
        <p className="font-[500] text-[#142548] text-[20px] dvmax:max-dh:text-[30px] sm:max-md:text-xl md:max-lg:text-[14px]">
          {content}
        </p>
      </div>
    </div>
  );
}

export default CharacterCarousel;
